.alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100000;
}

.alert-container {
    background-color: #fff;
    border-radius: 8px;
    width: 80%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
    display: flex;
    flex-direction: column;
    animation: zoomIn 0.4s ease;
}

.alert-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;
    box-sizing: border-box;
    justify-content: space-between;
    background: var(--modal-header-color);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

}

.alert-title {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
}

.alert-content {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 1.8;
}

.alert-content label {
    color: #000;
    display: inline-block;
    width: 100%;
    white-space: normal;
    word-wrap: break-word;
}

.alert-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding: 10px 20px;
    box-sizing: border-box;
}

.ok-button {
    min-width: 80px;
    padding: 7px 10px;
    border: none;
    border-radius: 30px;
    background-color: #212121;
    vertical-align: middle;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}

@keyframes zoomIn {
    from {
        transform: scale(0.5);
        opacity: 0;
    }

    to {
        transform: scale(1);
        opacity: 1;
    }
}

@media (min-width: 768px) {
    .alert-container {
        width: 350px;
    }
}

@media (min-width: 1200px) {
    .alert-container {
        width: 350px;
    }
}