.sign-in-main-container {
    width: 100%;
    height: calc(100vh - 48px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.login-page-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.sign-in-left-container {
    display: none;
}

.banner-label {
    width: 100%;
    font-size: 48px;
    font-weight: 600;
    color: var(--secondary-color);
    text-align: center;

}

.banner-text {
    font-size: 16px;
    color: #eaeaea;
    line-height: 1.7;
    text-align: center;
}

.sign-in-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80%;
    padding: 10px 20px;
    box-sizing: border-box;
    position: relative;
}

.sign-in-form {
    display: flex;
    width: 96%;
    flex-direction: column;
    padding: 1rem 1.5rem 2rem 1.5rem;
    border: 1px solid #dadada;
    box-sizing: border-box;
    background-color: #ffffff;
    box-shadow: 0 0 20px #eaeaea;
    border-radius: 0px;
    position: relative;
}

.sign-in-form h2 {
    margin-top: 6px;
    margin-bottom: 6px;
}
.password-input-container {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 1rem 0 0.5rem 0;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.show-hide-button {
    opacity: 0.6;
    cursor: pointer;
    border: none;
    background: transparent;
}

.sign-in-password-input {
    width: 90%;
    border: none;
    padding: 0.5rem;
    font-size: 1rem;
}
.sign-in-password-input:focus{
    border: none;
    outline: none;
}

.sign-in-input {
    margin: 1rem 0 0.5rem 0;
    padding: 0.5rem;
    font-size: 1rem;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.sign-in-input:focus {
    border: none;
    outline: none;
    border: 1px solid #666;
}

.sign-in-button {
    margin-top: 2rem;
    padding: 0.60rem;
    font-size: 1rem;
    font-weight: 600;
    color: #ffffff;
    background-color: var(--submit-button-color);
    text-transform: uppercase;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}


.input-validation-error {
    background-color: #fdf0ef;
    padding: 5px 19px;
    box-sizing: border-box;
    border-radius: 4px;
    color: rgb(167, 2, 2);
    margin-bottom: 0.5rem;
}

.forgot-password-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-bottom: 10px;
}

.sign-in-bottom-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px 0px 0px;
    color: #212121;
}

.link-button {
    background-color: transparent;
    border: none;
    font-size: 14px;
    font-weight: 600;
    color: #019494;
}

.forgot {
    font-weight: 400;
    cursor: pointer;
}

.forgot:hover {
    box-shadow: none;
}

.sign {
    color: #faa302;
    margin: 0px 10px;
    cursor: pointer;
}

.sign:hover {
    box-shadow: none;
    color: #faa302;
    text-decoration: underline;

}

.banner-top-right {
    position: absolute;
    width: 30%;
    height: 30%;
    top: 0;
    right: 0;
    opacity: 0.8;
}

.banner-bottom-left {
    position: absolute;
    width: 30%;
    height: 30%;
    bottom: 0;
    left: 0;
    transform: rotate(180deg);
}

/* Tablets and larger */
@media (min-width: 768px) {
    .sign-in-main-container {
        width: 100%;
        height: calc(100vh - 48px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* background-image: url("../../assets/images/back.jpg");
        background-size: cover;
        background-repeat: no-repeat; */
        position: relative;
    }

    .login-page-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
    }

    .sign-in-left-container {
        width: 60%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        padding: 30px;
        box-sizing: border-box;
        font-weight: 200;
    }

    .banner-label {
        width: 100%;
        font-size: 48px;
        font-weight: 600;
        color: #fab302;
        text-align: center;

    }

    .banner-text {
        font-size: 16px;
        color: #eaeaea;
        line-height: 1.7;
        text-align: center;
    }

    .sign-in-content {
        width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 80%;
        padding: 10px;
        box-sizing: border-box;
        position: relative;
    }

    .sign-in-form {
        display: flex;
        width: 100%;
        max-width: 280px;
        flex-direction: column;
        padding: 2rem;
        background-color: #ff0000;
        border: 1px solid #fafafa;
        border-radius: 6px;
        position: relative;
    }

    .sign-in-input {
        margin: 1rem 0 0.5rem 0;
        padding: 0.5rem;
        font-size: 1rem;
        border-radius: 4px;
        border: 1px solid #ccc;
    }

    .sign-in-button {
        margin-top: 10px;
        padding: 0.60rem;
        font-size: 1rem;
        font-weight: 600;
        color: #ffffff;
        text-transform: uppercase;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }


    .input-validation-error {
        color: red;
        margin-bottom: 1rem;
    }

    .forgot-password-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding-bottom: 10px;
    }

    .sign-in-bottom-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0px 0px 0px;
        color: #212121;
    }

    .link-button {
        background-color: transparent;
        border: none;
        font-size: 14px;
        font-weight: 600;
        color: #036bd9e3;
    }

    .forgot {
        font-weight: 400;
        cursor: pointer;
    }

    .forgot:hover {
        box-shadow: none;
    }

    .sign {
        color: #fab302;
        margin: 0px 10px;
        cursor: pointer;
    }

    .sign:hover {
        box-shadow: none;
        color: #fab302;
        text-decoration: underline;

    }

    .banner-top-right {
        position: absolute;
        width: 30%;
        height: 30%;
        top: 0;
        right: 0;
        opacity: 0.8;
    }

    .banner-bottom-left {
        position: absolute;
        width: 30%;
        height: 30%;
        bottom: 0;
        left: 0;
        transform: rotate(180deg);
    }
}

/* Desktops and larger */
@media (min-width: 1200px) {
    .sign-in-main-container {
        width: 100%;
        height: calc(100vh - 48px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    .login-page-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
    }

    .sign-in-left-container {
        width: 60%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        padding: 30px;
        box-sizing: border-box;
        font-weight: 200;
    }

    .banner-label {
        width: 100%;
        font-size: 48px;
        font-weight: 600;
        color: #fab302;
        text-align: center;

    }

    .banner-text {
        font-size: 16px;
        color: #eaeaea;
        line-height: 1.7;
        text-align: center;
    }

    .sign-in-content {
        width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 80%;
        padding: 10px;
        box-sizing: border-box;
        position: relative;
    }

    .sign-in-form {
        display: flex;
        width: 100%;
        max-width: 280px;
        flex-direction: column;
        padding: 2rem;
        background-color: #ffffff;
        border: 1px solid #cacaca;
        border-radius: 6px;
        position: relative;
    }

    .sign-in-input {
        margin: 1rem 0 0.5rem 0;
        padding: 0.5rem;
        font-size: 1rem;
        border-radius: 4px;
        border: 1px solid #ccc;
    }

    .sign-in-button {
        margin-top: 10px;
        padding: 0.60rem;
        font-size: 1rem;
        font-weight: 600;
        color: #ffffff;
        text-transform: uppercase;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }


    .input-validation-error {
        color: red;
        margin-bottom: 1rem;
    }

    .forgot-password-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding-bottom: 10px;
    }

    .sign-in-bottom-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0px 0px 0px;
        color: #212121;
    }

    .link-button {
        background-color: transparent;
        border: none;
        font-size: 14px;
        font-weight: 600;
        color: #036bd9e3;
    }

    .forgot {
        font-weight: 400;
        cursor: pointer;
    }

    .forgot:hover {
        box-shadow: none;
    }

    .sign {
        color: #fab302;
        margin: 0px 10px;
        cursor: pointer;
    }

    .sign:hover {
        box-shadow: none;
        color: #fab302;
        text-decoration: underline;

    }

    .banner-top-right {
        position: absolute;
        width: 30%;
        height: 30%;
        top: 0;
        right: 0;
        opacity: 0.8;
    }

    .banner-bottom-left {
        position: absolute;
        width: 30%;
        height: 30%;
        bottom: 0;
        left: 0;
        transform: rotate(180deg);
    }
}