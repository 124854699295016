html {
  min-height: 100%;
  padding: 0;
  margin: 0;
}

body {
  background: transparent;
  margin: 0;
  width: 100%;
  font-family: 'Mulish';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);

}
