.profile-menu {
  position: relative;
  display: inline-block;
}

.profile-button {
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  height: 36px;
  width: 36px;
  padding-top: 4px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffb301;
}
.profile-button:hover{
  color: #fa3c02;
}

.profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.menu-dropdown {
  position: absolute;
  top: 80%;
  right: 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0px;
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  list-style: none;
  width: 200px;
  z-index: 10000;
}

.menu-dropdown li {
  width: 100%;
  margin: 0px;
  padding: 8px 10px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: transparent;
  border-bottom: 1px solid #e1e1e1;
  cursor: pointer;
}
.menu-dropdown li:hover{
  background-color: #e2ebfb;
  font-weight: 600;
}

.menu-dropdown li button {
  color: #333;
  text-decoration: none;
  background: none;
  border: none;
  font-size: 16px;
  padding: 2px;
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.menu-dropdown li button:hover{
  border: none;
  box-shadow: none !important;
}
.menu-dropdown li button img{
  margin-right: 10px;
}
.sign-out-button {
  color: #cc4a43 !important;
  font-weight: 600;
  padding: 0px;
  font-size: 15px !important;
}
