.App {
  width: 100%;
  height: 100%;
  font-family: 'Mulish', 'sans-serif';
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.fallback-loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fallback-logo {
  width: 50px;
  height: 50px;
  animation: zoomInOut 2s infinite ease-in-out;
}

.main-fallback {
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 20px;
  line-height: 1.5;
  text-align: center;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
}

.app-content-container{
  width: 100%;
  height: calc(100vh - 48px);
  margin-top: 48px;
}
/* Tablets and larger */
@media (min-width: 768px) {
  .fallback-logo {
    width: 80px;
    height: 80px;
  }
}

/* Desktops and larger */
@media (min-width: 1200px) {
  .fallback-logo {
    width: 80px;
    height: 80px;
  }
}

/* Keyframes for zoom-in and zoom-out */
@keyframes zoomInOut {
  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 100px;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 100px;
}

::-webkit-scrollbar-thumb {
  border-radius: 100px;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
  background-color: #999;
}
:root {
  --main-color: #097994;
  --secondary-color: #faa302;
  --modal-header-color: #01637b;
  --submit-button-color: #323537;
  --text-color: #212529;
  --link-color: #01637b;
  --font-size: 16px;
}
