.loader-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.75);
    background-blend-mode: overlay;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;

  }
  .loader-box{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .loader-text{
    padding: 5px;
    color: #faa302;
  }
  
  .logo {
    width: 38px; /* Set the size of the logo */
    height: 38px;
    animation: spin 1.2s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .loader {
    width: 38px;
    height: 38px;
    border: 4px solid #faa302;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 0.8s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 
    