.score-modal-container {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0px 10px 20px 10px;
    box-sizing: border-box;
}

.result-container {
    display: flex;
    margin-top: 0px;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 10px 0px;
    box-sizing: border-box;
}
.result-message-container{
    display: flex;
    align-items: center;
}

.result-message {
    display: inline-block;
    width: 90%;
    text-align: left;
    font-size: 22px;
    font-weight: 600;
    color: #212529;
    padding: 0px 10px;
    box-sizing: border-box;
}

.result-label {
    display: inline-block;
    width: 100%;
    text-align: left;
    font-size: 30px;
    font-weight: bolder;
    color: #1f8747;
    padding: 0px 10px;
    box-sizing: border-box;
}
.failed{
    color: #cc4a43;
}

.score-details-label {
    padding-left: 10px;
    font-weight: 600;
}

.score-outer-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    padding: 10px;
    padding-top: 10px;
    box-sizing: border-box;
}

.score-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid #fafafa;
    box-shadow: 0px 0px 10px 0px #d7dade;
    border-radius: 4px;
    padding: 10px;
    box-sizing: border-box;
}

.score-container div {
    width: 100%;
}

.score-container label {
    display: inline-block;
    width: 70%;
    color: #212529;
    padding: 5px 0px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.0;
}

.success-span {
    margin: 2px 0px 2px 10px;
    display: inline-block;
    width: 40px;
    padding: 0px 10px;
    color: #fff;
    border-radius: 4px;
    background-color: #1f8747;
    font-weight: 600;
}

.failure-span {
    margin: 2px 0px 2px 10px;
    display: inline-block;
    width: 40px;
    padding: 0px 10px;
    color: #fff;
    border-radius: 4px;
    background-color: #cc4a43;
    font-weight: 600;
}

.popup-footer {
    width: 100%;
    padding: 16px 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.popup-footer button {
    width: 120px;
    margin: 0px 10px;
    padding: 8px 0px;
    border-radius: 4px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
}

button:hover {
    opacity: 0.8px;
    box-shadow: 0px 0px 6px 1px #cbd0d6;
}

.close-button {
    background-color: transparent;
    border: 2px solid #212121;
    color: #212121;
}

.play-again-button {
    background-color: #212121;
    border: 2px solid #212121;
    color: #fff;
}

.congrats-animation {
    font-size: 2rem;
    color: #ffcc00;
    text-align: center;
    animation: pop-up 1s ease-out, fade-out 1s 2s ease-out forwards;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* Pop-up and fade-out animation */
@keyframes pop-up {
    0% {
        transform: scale(0);
        opacity: 0;
    }

    50% {
        transform: scale(1.1);
        opacity: 1;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes fade-out {
    /* from { opacity: 1; }
    to { opacity: 0; } */
}

/* Tablets and larger */
@media (min-width: 768px) {
    .score-modal-container {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
    }

    .result-container {
        display: flex;
        margin-top: 0px;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        padding: 10px 0px;
        box-sizing: border-box;
    }

    .result-message {
        display: inline-block;
        width: 100%;
        text-align: left;
        font-size: 18px;
        font-weight: 600;
        color: #212529;
        padding: 0px 10px;
        box-sizing: border-box;
    }

    .result-label {
        display: inline-block;
        width: 100%;
        text-align: left;
        font-size: 32px;
        font-weight: bolder;
        color: #1f8747;
        padding: 0px 10px;
        box-sizing: border-box;
    }

    .score-details-label {
        padding: 10px 0px 0px 10px;
        font-weight: 600;
    }

    .score-outer-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        padding: 10px;
        padding-top: 10px;
        box-sizing: border-box;
    }

    .score-container {
        width: 48%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border: 1px solid #fafafa;
        box-shadow: 0px 0px 10px 0px #d7dade;
        border-radius: 4px;
        padding: 16px 12px;
        box-sizing: border-box;
    }

    .score-container div {
        width: 100%;
    }

    .score-container label {
        display: inline-block;
        width: 65%;
        color: #212529;
        padding: 5px 0px;
        font-size: 16px;
        font-weight: 500;
    }

    .success-span {
        padding-left: 5px;
        color: #1f8747;
        font-weight: 600;
    }

    .failure-span {
        padding-left: 5px;
        color: #cc4a43;
        font-weight: 600;
    }

    .popup-footer {
        width: 100%;
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .popup-footer button {
        width: 120px;
        margin: 0px 10px;
        padding: 8px 0px;
        border-radius: 4px;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 600;
        cursor: pointer;
    }

    button:hover {
        opacity: 0.8px;
        box-shadow: 0px 0px 6px 1px #cbd0d6;
    }

    .close-button {
        background-color: transparent;
        border: 2px solid #212529;
        color: #212529;
    }

    .play-again-button {
        background-color: #212529;
        border: 2px solid #212529;
        color: #fff;
    }

    .congrats-animation {
        font-size: 2rem;
        color: #ffcc00;
        text-align: center;
        animation: pop-up 1s ease-out, fade-out 1s 2s ease-out forwards;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

/* Desktops and larger */
@media (min-width: 1200px) {
    .score-modal-container {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
    }

    .result-container {
        display: flex;
        margin-top: 0px;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        padding: 10px 0px;
        box-sizing: border-box;
    }

    .result-message {
        display: inline-block;
        width: 100%;
        text-align: left;
        font-size: 18px;
        font-weight: 600;
        color: #212529;
        padding: 0px 10px;
        box-sizing: border-box;
    }

    .result-label {
        display: inline-block;
        width: 100%;
        text-align: left;
        font-size: 32px;
        font-weight: bolder;
        color: #1f8747;
        padding: 0px 10px;
        box-sizing: border-box;
    }

    .score-details-label {
        padding: 10px 0px 0px 10px;
        font-weight: 600;
    }

    .score-outer-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        padding: 10px;
        padding-top: 10px;
        box-sizing: border-box;
    }

    .score-container {
        width: 48%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border: 1px solid #fafafa;
        box-shadow: 0px 0px 10px 0px #d7dade;
        border-radius: 4px;
        padding: 16px 12px;
        box-sizing: border-box;
    }

    .score-container div {
        width: 100%;
    }

    .score-container label {
        display: inline-block;
        width: 70%;
        color: #212529;
        padding: 5px 0px;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.0;
    }

    .success-span {
        padding-left: 10px;
        color: #1f8747;
        font-weight: 600;
    }

    .failure-span {
        padding-left: 10px;
        color: #cc4a43;
        font-weight: 600;
    }

    .popup-footer {
        width: 100%;
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .popup-footer button {
        width: 120px;
        margin: 0px 10px;
        padding: 8px 0px;
        border-radius: 4px;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 600;
        cursor: pointer;
    }

    button:hover {
        opacity: 0.8px;
        box-shadow: 0px 0px 6px 1px #cbd0d6;
    }

    .close-button {
        background-color: transparent;
        border: 2px solid #212529;
        color: #212529;
    }

    .play-again-button {
        background-color: #212529;
        border: 2px solid #212529;
        color: #fff;
    }

    .congrats-animation {
        font-size: 2rem;
        color: #ffcc00;
        text-align: center;
        animation: pop-up 1s ease-out, fade-out 1s 2s ease-out forwards;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}