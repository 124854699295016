.portal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100000;
}

.portal-container {
    background-color: #fff;
    border-radius: 8px;
    width: 90%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
    animation: zoomIn 0.4s ease;
}
.modal-header{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  box-sizing: border-box;
  justify-content: space-between;
  background: var(--modal-header-color);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

}
.modal-title
{
  font-size: 20px;
  font-weight: 600;
  color: #fff;
}
.portal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}
.white{
  color: #fff;
}
.modal-content{
  width: 100%;
  padding: 0px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.8;

}
.modal-footer{
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0px 20px;
  box-sizing: border-box;
}
@keyframes zoomIn {
    from {
      transform: scale(0.5);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  @media (min-width: 768px) {
  .portal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100000;
}

.portal-container {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 92%;
    position: relative;
    animation: zoomIn 0.4s ease;
}
.modal-header{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  box-sizing: border-box;
  justify-content: space-between;
  background-color: var(--modal-header-color);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

}
.modal-title
{
  font-size: 20px;
  font-weight: 600;
  color: #fff;
}
.portal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}
.white{
  color: #fff;
}
.modal-content{
  width: 100%;
  padding: 0px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.8;
  padding-bottom: 54px;

}
.modal-footer{
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0px 20px;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
}
}
@media (min-width: 1200px) {
  .portal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100000;
}

.portal-container {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 60%;
    position: relative;
    animation: zoomIn 0.4s ease;
}
.modal-header{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  box-sizing: border-box;
  justify-content: space-between;
  background-color: var(--modal-header-color);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

}
.modal-title
{
  font-size: 20px;
  font-weight: 600;
  color: #fff;
}
.portal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}
.white{
  color: #fff;
}
.modal-content{
  width: 100%;
  padding: 0px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.8;
  padding-bottom: 54px;

}
.modal-footer{
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0px 20px;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
}
}

  