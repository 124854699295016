.topbar-container {
  width: 100%;
  height: 52px;
  /* background-color: #00505c; */
  background-color: var(--main-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 12px;
  box-sizing: border-box;
  position: fixed;
  z-index: 1005;
}

.topbar-left-container {
  margin-left: 1%;
  font-size: 30px;
  color: #fff;
  font-weight: bold;
  text-align: left;
}

.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.topbar-logo{
  width: 180px;
  height: 40px;
}

.title {
  font-size: 24px;
  color: #facc02;
  text-align: center;
}

.slogan {
  font-size: 10px;
  text-align: center;
  color: #dadada;
  font-style: italic;

}

.topbar-right-container {
  display: flex;
  justify-content: right;
}
.topbar-signin-button{
  border: none;
  background-color: transparent;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  text-transform: capitalize;
}
