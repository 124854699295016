.quiz-container {
  width: 100%;
}

.quiz-main-container {
  width: 100%;
  height: 100%;
  padding: 3px 0px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  overflow-y: auto;
}
.quiz-header{
  width: 100%;
  padding: 8px 3%;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
}
.quiz-type-label{
  font-size: 16px;
  color: #212121;
  border-radius: 30px;
  font-weight: 600;
  box-sizing: border-box;
}

.skills-dropdown{
  margin: 0px;
  padding: 4px;
  border-radius: 4px;
  font-family: 'Mulish';
  font-size: 16px;
}
.skills-dropdown:focus{
  outline: none;
}

.question-outer-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.question-header {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 3%;
  box-sizing: border-box;
  background-color: #fff;
}
.question-body{
  height: calc(100vh - 220px);
  overflow-y: auto;
  padding: 0% 3%;
}
.question-container {
  border: 1px solid #fff;
  border-radius: 4px;
  background: #f5f8fa;
  color: #212529;
  font-size: 18px;
  font-weight: 500;
  padding: 16px 16px 10px 16px;
  text-align: left;
  margin-bottom: 20px;
}

.question-label {
  display: inline-block;
  font-size: 17px;
  font-weight: 600;
  padding-right: 50px;
  padding-top: 6px;
}

.answers-container {
  display: flex;
  flex-direction: column;
  padding: 0px;
  box-sizing: border-box;
}

.option-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border: 0.5px solid #e0e0e0;
  border-radius: 20px;
  background: #fafafa;
  color: #212529;
  font-size: 16px;
  font-weight: 400;
  margin: 12px 0px;
  padding: 10px;
  text-align: left;
}

.option-selected {
  border: 1px solid #9fd8b5;
  background: #e4fee9;
}

.option-checkbox {
  width: 16px !important;
  height: 16px !important;
  border: none;
  accent-color: #01a941;
}
.option-checkbox:focus{
  accent-color: #01a941;
}

.option-label {
  padding: 0px 10px;
  pointer-events: none;
}

.quiz-footer {
  width: 100%;
  padding: 16px 16px;
  box-sizing: border-box;
  border-top: 1px solid #dadada;
  position: fixed;
  bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: transparent;
}

.quiz-footer-content {
  display: flex;
  justify-content: center;
}

.action-button {
  min-width: 120px;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #212529;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  margin: 0px 10px;
  cursor: pointer;
}

.submit-button {
  min-width: 120px;
  padding: 8px 10px;
  border: none;
  border-radius: 30px;
  background-color: #212121;
  vertical-align: middle;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  margin: 0px 10px;
}
.submit-button:disabled , .skip-button:disabled{
  opacity: 0.75;
  cursor: not-allowed;

}

.skip-button {
  min-width: 120px;
  padding: 8px 10px;
  margin: 0px 10px;
  border: 1px solid #c0423b;
  border-radius: 30px;
  background-color: #fff;
  color: #c0423b;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.fade-in {
  opacity: 0;
  animation: fadeIn 0.3s forwards ease-in-out;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

/* Tablets and larger */
@media (min-width: 768px) {
  .quiz-footer {
    justify-content: flex-end;
  }
  
  .quiz-footer-content {
    justify-content: flex-end;
  }
}

/* Desktops and larger */
@media (min-width: 1200px) {
  .quiz-footer {
    justify-content: flex-end;
  }
  
  .quiz-footer-content {
    justify-content: flex-end;
  }
}